import { Component, DefineComponent, VNode, createApp, h, watch } from "vue"
import { appType } from "@/main"

type Content = ReturnType<typeof h>
interface UseComponentConfig<T = any> {
    component: VNode | Component | Content | DefineComponent
    timeout?: number
    props?: T
    [property: string]: any
}

export const useToast = (config: UseComponentConfig) => {
    const { component, props, timeout } = config
    const div = document.createElement('div')

    /**
     * 展示组件
     * @param app 组件实例
     * @param time 
    */
    const mountComponent = (app: appType, timeout = 3) => {
        // 创建文档碎片
        document.body.appendChild(div)
        // 将组件挂载在文档碎片上
        app.mount(div)

        setTimeout(() => {
            unmountComponent(app)
        }, timeout * 1000)
    }

    /**
     * 卸载组件
     * @param app 组件实例
     */
    const unmountComponent = (app: appType) => {
        app.unmount()
        document.body.removeChild(div)
    }

    const componentInstance = createApp(component, {
        ...props
    })

    mountComponent(componentInstance, timeout)

}

