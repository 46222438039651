import { StorageKey } from "@/models/enums"
import storage from "./storage"
import useClipboard from 'vue-clipboard3'
import { useToast } from '@/utils/useToast'
import Toast from '@/components/common/popup/toast.vue'
import { i18n } from '@/i18n'
const $t = i18n.global.t
const PI = 3.1415926535897932384626
const a = 6378245.0 //卫星椭球坐标投影到平面地图坐标系的投影因子。
const ee = 0.00669342162296594323 //椭球的偏心率。

/**
 * Formats the time into a string with minutes and seconds.
 *
 * @param {number} time - The time to be formatted in seconds.
 * @return {string} The formatted time string in the format "mm:ss".
 */
export const timerFormat = (time: number) => {
  let m = Math.floor(time / 60).toString()
  let s = (time % 60).toString()
  m = m.length == 1 ? '0' + m : m
  s = s.length == 1 ? '0' + s : s
  return `${m}:${s}`
}

/**
 * Formats the given time in seconds into a human-readable format.
 *
 * @param {number} seconds - The time to be formatted in seconds.
 * @return {Object|string} An object with hours and minutes if the time is greater than or equal to 60 seconds, otherwise a string representing the time in minutes.
 */
export const formatTime = (seconds: number): { hours: number, minutes: number } => {
  if (seconds < 3600) {
    return {
      hours: 0,
      minutes: Math.floor(seconds / 60)
    }
  } else {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    return {
      hours,
      minutes
    }
  }
}

/**
 * Generates a random GUID (Globally Unique Identifier) using the version 4 format.
 *
 * @return {string} The generated GUID.
 */
export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * Generates a random string of length 8 using characters from the given character set.
 *
 * @return {string} The randomly generated string.
 */
export const generateRandomString = () => {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var randomString = ''

  for (var i = 0;i < 8;i++) {
    var randomIndex = Math.floor(Math.random() * chars.length)
    randomString += chars[randomIndex]
  }

  return randomString
}

export const countdown = (timerObject: any, clearStorage = false, key?: any) => {
  if (timerObject.timer) {
    clearTimeout(timerObject.timer)
  }
  timerObject.timer = setInterval(async () => {
    timerObject.totalTime -= 1
    if (timerObject.totalTime <= 0) {
      if (timerObject.timer) {
        clearTimeout(timerObject.timer)
        timerObject.timer = null
        timerObject.totalTime = 0
        if (clearStorage) {
          window.localStorage.removeItem(key)
        }
      }
    }
  }, 1000)
}

/**
 * Creates a debounced version of the given function.
 *
 * @param {T} fn - The function to be debounced.
 * @param {number} [delay=1000] - The delay in milliseconds for the debounce.
 * @return {(...args: Parameters<T>) => void} - The debounced function.
 */
export function useDebounce<T extends (...args: any[]) => void>(fn: T, delay: number = 1000): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
      timeout = null
    }, delay)
  }
}

/**
 * Creates a throttled version of the given function.
 *
 * @param {T} fn - The function to be throttled.
 * @param {number} [delay=1000] - The delay in milliseconds.
 * @returns {(...args: Parameters<T>) => void} - The throttled function.
 */
export function useThrottle<T extends (...args: any[]) => void>(fn: T, delay: number = 1000): (...args: Parameters<T>) => void {
  let lastTime = 0

  return function (...args: Parameters<T>) {
    const now = +new Date()

    if (now - lastTime > delay) {
      fn(...args)
      lastTime = now
    }
  }
}

/**
 * Retrieves the URL parameters from the given URL string.
 *
 * @param {string} url - The URL string from which to extract the parameters.
 * @return {object} An object containing the URL parameters as key-value pairs.
 */
export const getUrlParams = (url: string) => {
  if (!url.includes('?')) return {}
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
  let obj = {} as any
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length;i < len;i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1]
  }
  return obj
}

export const clearStorage = () => {
  storage.clearItem(StorageKey.MQTT_OPTION)
  storage.clearItem(StorageKey.DOCK_SN)
  storage.clearItem(StorageKey.DRONE_SN)
  storage.clearItem(StorageKey.ORDER_ID)
  storage.clearItem(StorageKey.FLIGHT_STATUS)
}

/**
 * Downloads a file based on the provided URL and type.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} type - The type of the file ('image', 'video', 'audio', or other).
 * @return {Promise<void>} A promise that resolves after the file download is initiated.
 */
export const downloadFile = (url: string, type: string) => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('a')
    link.href = url
    if (type === 'image') {
      link.setAttribute('download', `${url}.jpg`)
    } else if (type === 'video') {
      link.setAttribute('download', `${url}.mp4`)
    } else if (type === 'audio') {
      link.setAttribute('download', `${url}.mp3`)
    } else {
      // 处理其他类型的资源
      link.setAttribute('download', '')
    }
    link.setAttribute('target', '_blank')
    link.addEventListener('click', resolve)
    link.click()
    document.body.appendChild(link)
    document.body.removeChild(link)
  })
}

/**
 * Returns a promise that resolves after the specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to wait before resolving the promise.
 * @return {Promise<void>} A promise that resolves after the specified number of milliseconds.
 */
export const timeout = (ms: number) => {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}

/**
 * Encodes the given object into a base64-encoded string.
 *
 * @param {object} data - The object to be encoded.
 * @return {string} The base64-encoded string representation of the object.
 */
export const encoding = (data: object) => {
  return window.btoa(JSON.stringify(data))
}

/**
 * Decodes a base64-encoded string into its original JSON format.
 *
 * @param {string} data - The base64-encoded string to decode.
 * @return {object} The decoded JSON object.
 */
export const decoding = (data: string) => {
  return JSON.parse(window.atob(data))
}

/**
 * Retrieves the WeChat code from the current URL's query parameters.
 *
 * @return {string} The WeChat code extracted from the URL.
 */
export const getWechatCode = () => {
  return window.location.search.substring(6).split('&')[0]
}

/**
 * Checks if the current device is running WeChat browser.
 *
 * @return {boolean} Returns true if the device is running WeChat browser, false otherwise.
 */
export const isWechatBrowser = () => {
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export const isMobileAndWeChatBrowser = () => {
  return isMobile() && isWechatBrowser()
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

/**
 * Checks if the current device is running iOS.
 *
 * @return {boolean} Returns true if the device is running iOS, false otherwise.
 */
export const isIOS = () => {
  return /iphone|ipad|ipod/i.test(window.navigator.userAgent)
}

/**
 * Checks if the current device is running on Android.
 *
 * @return {boolean} Returns true if the device is running on Android, false otherwise.
 */
export const isAndroid = () => {
  return /android/i.test(window.navigator.userAgent)
}

/**
 * Checks if the array elements form a decreasing trend.
 *
 * @param {number[]} arr - The array to check for a decreasing trend.
 * @return {boolean} Returns true if the array elements form a decreasing trend, false otherwise.
 */
export const isDecreasingTrend = (arr: number[]) => {
  return arr.reduce((acc, curr, i, arr) => {
    if (i === 0) {
      return true
    }
    return acc && curr < arr[i - 1]
  }, true)
}

export const copyText = async (text: string) => {
  const { toClipboard } = useClipboard()
  try {
    await toClipboard(text)
    useToast({
      component: Toast,
      props: {
        content: $t('common.copySuccess'),
      },
      timeout: 2,
    })
  } catch (e) {
    useToast({
      component: Toast,
      props: {
        content: $t('common.copyFailed'),
      },
      timeout: 2,
    })
    console.error(e)
  }
}

export const formatNumber = (num: number) => {
  return num.toString().padStart(2, '0')
}

export const getSignUrl = () => {
  let signLink = ''
  let ua = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(ua)) {
    signLink = storage.getItem(StorageKey.WX_CODE_URL)
    if (!signLink) signLink = window.location.href
  } else {
    signLink = window.location.href
  }
  return signLink
}

//转化经度
function transformlng(lng: number, lat: number) {
  let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
  ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
  return ret
}

//转化纬度
function transformlat(lng: number, lat: number) {
  let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
  ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
  ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
  ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
  return ret
}
//判断时候在国内还是国外
function out_of_china(lon: number, lat: number) {
  if (lon < 72.004 || lon > 137.8347) {
    return true
  }
  if (lat < 0.8293 || lat > 55.8271) {
    return true
  }
  return false
}
//wgs84 to gcj02   地球坐标系 转 火星坐标系
export function wgs84togcj02(lng: number, lat: number) {
  let dlat = transformlat(lng - 105.0, lat - 35.0)
  let dlng = transformlng(lng - 105.0, lat - 35.0)
  let radlat = (lat / 180.0) * PI
  let magic = Math.sin(radlat)
  magic = 1 - ee * magic * magic
  let sqrtmagic = Math.sqrt(magic)
  dlat =
    (dlat * 180.0) /
    (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
  dlng =
    (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
  let mglat = lat + dlat
  let mglng = lng + dlng

  return [mglng, mglat]
}

//gcj02 to wgs84  火星坐标系 转 地球坐标系
export function gcj02towgs84(lng: number, lat: number) {
  const originalLngSign = Math.sign(lng)
  const originalLatSign = Math.sign(lat)
  lat = Math.abs(lat)
  lng = Math.abs(lng)
  let dlat = transformlat(lng - 105.0, lat - 35.0)
  let dlng = transformlng(lng - 105.0, lat - 35.0)
  let radlat = lat / 180.0 * PI
  let magic = Math.sin(radlat)
  magic = 1 - ee * magic * magic
  let sqrtmagic = Math.sqrt(magic)
  dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
  dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
  let mglat = lat + dlat
  let mglng = lng + dlng
  let lngs = lng * 2 - mglng
  let lats = lat * 2 - mglat
  let finalLng = originalLngSign * lngs
  let finalLat = originalLatSign * lats

  return [finalLng, finalLat]
}