import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user'
import { PageName } from '@/models/enums'
import { isMobileAndWeChatBrowser } from '@/utils/util'
import { IS_DEV } from '@/config/api'
const otherBrowerWhite = ['Wechat', 'Video']
const routes: any[] = [
  {
    path: '/',
    redirect: { name: PageName.MAIN },
  },
  {
    path: '/',
    name: PageName.MAIN,
    component: () => import('@/views/main/index.vue'),
    redirect: { name: PageName.HOME },
    children: [
      {
        path: '/home',
        name: PageName.HOME,
        component: () => import('@/views/home/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/memory',
        name: PageName.MEMORY,
        component: () => import('@/views/memory/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/order',
        name: PageName.ORDER,
        component: () => import('@/views/order/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/profile',
        name: PageName.PROFILE,
        component: () => import('@/views/profile/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
    ]
  },
  {
    path: '/home/select-wayline/:id/:rn',
    name: PageName.SELECT_WAYLINE,
    component: () => import('@/views/home/pages/select-wayline-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/aerial/:journey_id/:is_started/:late_time',
    name: PageName.AERIAL,
    component: () => import('@/views/aerial/index.vue'),
    meta: {
      isAuthn: true,
    }
  },
  {
    path: '/login',
    name: PageName.LOGIN,
    component: () => import('@/views/login/index.vue'),
    meta: {
      isAuthn: false,
    }
  },
  {
    path: '/binding-phone',
    name: PageName.BINDING_PHONE,
    component: () => import('@/views/login/pages/binding-phone.vue'),
    meta: {
      isAuthn: false,
    }
  },
  {
    path: '/tutorials',
    name: PageName.TUTORIALS,
    component: () => import('@/views/tutorials/index.vue'),
  },
  {
    path: '/memory/hotpot-detail/:reference_number/:order_id',
    name: PageName.HOTPOT_DETAIL,
    component: () => import('@/views/memory/pages/hotpot-detail-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/memory/hotpot-detail/video/:hotspot/:order/:token',
    name: PageName.VIDEO,
    component: () => import('@/views/memory/pages/video-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: false,
    }
  },
  {
    path: '/order/order-detail/:id',
    name: PageName.ORDER_DETAIL,
    component: () => import('@/views/order/pages/order-detail-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/notice',
    name: PageName.NOTICE,
    component: () => import('@/views/profile/pages/notice-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/notice/notice-detail/:id',
    name: PageName.NOTICE_DETAIL,
    component: () => import('@/views/profile/pages/notice-detail-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/language',
    name: PageName.LANGUAGE,
    component: () => import('@/views/profile/pages/language-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: false,
    }
  },
  {
    path: '/profile/setting',
    name: PageName.SETTING,
    component: () => import('@/views/profile/pages/setting-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/service-agreement',
    name: PageName.SERVICE_AGREEMENT,
    component: () => import('@/views/profile/pages/service-agreement-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/modify-pn',
    name: PageName.MODIFY_PN,
    component: () => import('@/views/profile/pages/modify-pn-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/privacy-policy',
    name: PageName.PRIVACY_POLICY,
    component: () => import('@/views/profile/pages/privacy-policy-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/about-mss',
    name: PageName.ABOUT_MSS,
    component: () => import('@/views/profile/pages/about-mss-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/mss-wechat',
    name: PageName.WECHAT,
    component: () => import('@/views/wechat/index.vue'),
    meta: {
      isAuthn: false,
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  const name = to.name as string
  if (name === 'Video') {
    next()
  }
  if (!isMobileAndWeChatBrowser() && !otherBrowerWhite.includes(name) && !IS_DEV) {
    next({ name: PageName.WECHAT })
  }
  const userStore = useUserStore()
  const withNavbar = to.meta && to.meta.withNavbar
  const lastPage = from.meta && from.meta.lastPage
  const routerHistoryList = userStore.routerHistoryList
  if (from.name && to.name && to.name === PageName.LOGIN) {
    userStore.pageNameBeforeLogin = from.name as any
  }
  if (to.name && to.name === PageName.HOME) {
    userStore.routerHistoryList = []
  }
  if (withNavbar && routerHistoryList[routerHistoryList.length - 1] != to.name) {
    if (!lastPage) {
      userStore.routerHistoryList.push(from.name)
    }
  }
  next() // 必须调用next()来resolve这个钩子
})
